import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { AccordionDetails, AccordionSummary, alpha, Box, Slider, styled } from '@mui/material';
import { Button } from '@elements';
import { pxToRem } from '@themes';
var PageContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    minHeight: '100vh'
  };
  return styles;
});
var SectorsTable = styled('table')(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    position: 'relative',
    width: '100%',
    borderSpacing: "0 ".concat(theme.typography.pxToRem(2)),
    thead: {
      tr: {
        th: {
          textAlign: 'left',
          padding: theme.spacing(0, 1)
        }
      }
    },
    tbody: {
      tr: {
        cursor: 'pointer',
        ':hover': {
          td: {
            backgroundColor: alpha(theme.palette.white.main, 0.06),
            ':last-child': {
              span: {
                color: theme.palette.internationalOrange.main
              },
              svg: {
                path: {
                  fill: theme.palette.internationalOrange.main
                }
              }
            }
          }
        },
        td: {
          position: 'relative',
          backgroundColor: alpha(theme.palette.white.main, 0.03),
          textAlign: 'left',
          padding: theme.spacing(1.75),
          ':first-child': {
            borderRadius: "".concat(theme.typography.pxToRem(12), " 0 0 ").concat(theme.typography.pxToRem(12))
          },
          ':last-child': {
            borderRadius: "0 ".concat(theme.typography.pxToRem(12), " ").concat(theme.typography.pxToRem(12), " 0")
          }
        }
      }
    }
  };
  return styles;
});
var ShowMoreButton = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '&&': {
      backgroundColor: 'transparent',
      padding: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: theme.typography.pxToRem(12),
      border: "".concat(theme.typography.pxToRem(2), " solid ").concat(alpha(theme.palette.white.main, 0.03)),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.palette.jumbo.main,
      textTransform: 'uppercase',
      ':hover': {
        color: theme.palette.white.main,
        border: "".concat(theme.typography.pxToRem(2), " solid transparent"),
        backgroundColor: alpha(theme.palette.white.main, 0.06)
      }
    }
  };
  return styles;
});
var EventSchema = styled(Box)(function (_ref4) {
  var _divSvg;

  var theme = _ref4.theme;
  var styles = {
    position: 'relative',
    width: '100%',
    '> div > svg': (_divSvg = {
      width: '100%',
      height: theme.typography.pxToRem(465)
    }, _defineProperty(_divSvg, theme.breakpoints.down('md'), {
      height: 'auto',
      maxHeight: pxToRem(400),
      marginLeft: pxToRem(-16),
      width: "calc(100% + ".concat(pxToRem(28), ")")
    }), _defineProperty(_divSvg, 'path[fill]:not(.disabled)', {
      cursor: 'pointer'
    }), _defineProperty(_divSvg, 'path[fill].disabled', {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.3
    }), _defineProperty(_divSvg, 'path[fill].active', {
      opacity: 0.5
    }), _divSvg)
  };
  return styles;
});
var EventSchemaContainer = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    height: '100%'
  };
  return styles;
});
var PriceSlider = styled(Slider)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    '.MuiSlider-thumb': {
      width: theme.typography.pxToRem(12),
      height: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.internationalOrange.main,
      ':hover, &.Mui-focusVisible': {
        boxShadow: "0 0 0 8px ".concat(alpha(theme.palette.internationalOrange.main, 0.16))
      },
      ':last-child': {
        '.MuiSlider-valueLabel': {
          color: theme.palette.internationalOrange.main
        }
      },
      '.MuiSlider-valueLabel': {
        backgroundColor: '#393a3b',
        borderRadius: theme.typography.pxToRem(4),
        padding: theme.spacing(0.5, 1.25),
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        color: theme.palette.white.main
      }
    },
    '.MuiSlider-rail': {
      backgroundColor: alpha(theme.palette.white.main, 0.12)
    },
    '.MuiSlider-track': {
      background: "linear-gradient(90deg, #BA3B00 0%, #FF5100 100%)",
      border: 'none'
    },
    '.MuiSlider-mark': {
      display: 'none'
    },
    '.MuiSlider-markLabel': {
      color: theme.palette.white.main
    }
  };
  return styles;
});
var SectorInfo = styled(Box)(function (_ref7) {
  var theme = _ref7.theme,
      $open = _ref7.$open,
      $isSticky = _ref7.$isSticky;

  var styles = _defineProperty({
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(528),
    width: "".concat(theme.typography.pxToRem(452), "!important"),
    zIndex: 1,
    borderRadius: theme.typography.pxToRem(12),
    background: '#2F3031',
    boxShadow: "0px 0px 33.818px 0px rgba(0, 0, 0, 0.25)",
    marginTop: $isSticky ? theme.spacing(7.5) : theme.spacing(3),
    marginLeft: theme.spacing(7.5)
  }, theme.breakpoints.down('lg'), {
    position: 'fixed',
    marginLeft: theme.spacing(0),
    top: 'unset !important',
    bottom: '0 !important',
    left: '0 !important',
    right: '0 !important',
    margin: 'auto !important',
    width: '100vw !important',
    maxWidth: theme.typography.pxToRem(728),
    justifyContent: 'unset',
    gap: pxToRem(16),
    borderRadius: '0'
  });

  if ($open) {
    styles.display = 'flex';
  }

  return styles;
});
var SelectSectorButton = styled(Button)(function (_ref8) {
  var theme = _ref8.theme;
  var styles = {
    '&&': {
      backgroundColor: 'transparent',
      padding: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: theme.typography.pxToRem(12),
      border: "".concat(theme.typography.pxToRem(2), " solid ").concat(theme.palette.internationalOrange.main),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.palette.white.main,
      textTransform: 'uppercase',
      ':hover': {
        border: "".concat(theme.typography.pxToRem(2), " solid transparent"),
        backgroundColor: theme.palette.internationalOrange.main
      }
    }
  };
  return styles;
});
var LegendIndicator = styled(Box)(function (_ref9) {
  var theme = _ref9.theme,
      color = _ref9.color;
  var styles = {
    width: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: color,
    marginRight: theme.spacing(1)
  };
  return styles;
});
var SchemaBox = styled(Box)(function (_ref10) {
  var theme = _ref10.theme,
      $isSticky = _ref10.$isSticky;
  var styles = {
    marginTop: $isSticky ? theme.spacing(7.5) : 0
  };
  return styles;
});
var TableGlass = styled(Box)(function (_ref11) {
  var theme = _ref11.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    top: theme.typography.pxToRem(40),
    width: '100%',
    height: "calc(100% - ".concat(theme.typography.pxToRem(40), ")"),
    backdropFilter: "blur(".concat(theme.typography.pxToRem(2), ")"),
    zIndex: 1
  };
  return styles;
});
var SectorRowGlass = styled(Box)(function (_ref12) {
  var theme = _ref12.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backdropFilter: "blur(".concat(theme.typography.pxToRem(2), ")"),
    zIndex: 0
  };
  return styles;
});
var StyledAccordionSummary = styled(AccordionSummary)(function (_ref13) {
  var _styles2;

  var theme = _ref13.theme;
  var styles = (_styles2 = {
    backgroundColor: "".concat(theme.colors.tetriary, " !important"),
    width: '100%',
    minHeight: "".concat(pxToRem(40), " !important"),
    borderRadius: "".concat(pxToRem(12), " !important"),
    border: "".concat(pxToRem(2), " solid rgba(255, 255, 255, 0.03) !important")
  }, _defineProperty(_styles2, theme.breakpoints.up('desktop'), {
    display: 'none !important'
  }), _defineProperty(_styles2, '& .MuiAccordionSummary-content', {
    justifyContent: 'center',
    margin: '8px'
  }), _defineProperty(_styles2, '& .MuiAccordionSummary-expandIconWrapper', {
    position: 'absolute',
    left: '49%',
    top: '6px',
    // transform:  'translate(-50%, -50%)',
    marginLeft: pxToRem(84)
  }), _styles2);
  return styles;
});
var StyledAccordionDetails = styled(AccordionDetails)(function (_ref14) {
  var theme = _ref14.theme;

  var styles = _defineProperty({
    paddingTop: pxToRem(36)
  }, theme.breakpoints.down('tablet'), {
    paddingTop: pxToRem(0)
  });

  return styles;
});
var PriceSliderContainer = styled(Box)(function (_ref15) {
  var theme = _ref15.theme;

  var styles = _defineProperty({}, theme.breakpoints.between('tablet', 'desktop'), {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
  });

  return styles;
});
export { EventSchema, EventSchemaContainer, LegendIndicator, PageContainer, PriceSlider, PriceSliderContainer, SchemaBox, SectorInfo, SectorRowGlass, SectorsTable, SelectSectorButton, ShowMoreButton, StyledAccordionDetails, StyledAccordionSummary, TableGlass };